<template>
  <div id="app">
    <div class="row">
      <!-- OCS SPORT -->
      <div class="col-lg-4 col-sm-12 backgroundImageOCSSport">
        <div class="outerBlockOCS_Sport">
          <div class="logo">
            <img
              class="logoPos ocsLogo"
              src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2023/03/OCS__Sport_Logo_2017_WO.png"
            />
          </div>
          <!-- <div class="MainHeaderText">
            <span class="HeaderText">World Leading</span>
            <span class="HeaderBold">Golf Software</span>
            <span class="HeaderText">Provider</span>
          </div> -->
          <div class="MainHeaderText">
            <p class="HeaderBold">World Leading Golf Software Provider</p>
          </div>
          <div class="container OCSSport_TextBlock">
            <div class="topTextSection">
              <p>
                OCS Sport offers bespoke tour management software, event management, apps and websites to some of the world’s largest golf tours. We also provide fully integrated venue management systems for sports clubs and facilities.
              </p>
            </div>
            <div class="mediumTextSection">
              <p>
                Visit our OCS Sport website to see our full list of services
              </p>
            </div>
          </div>
          <div class="outerBTN">
            <div class="buttonDiv">
              <a href="https://ocs-sport.com/">
                <button type="button" class="btn btn-primary visitOCSSport">
                  Visit OCS Sport
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- OCS DIGITAL -->
      <div class="col-lg-4 col-sm-12 backgroundImageOCSStudios">
        <div class="outerBlockOCS_studios">
          <div class="logoOCSStudio">
            <img
              class="logoPos ocsDigitalLogo"
              src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2023/05/OCS_Digital_425x425.png"
            />
          </div>
          <!-- <div class="MainHeaderText">
            <span class="HeaderText_OCS_Studio">Digital design & development <br> studios specialising in ...</span>
          </div> -->
          <div class="SpecialisingText">
            <p class="DigitalSpecialsing">
              Digital Design & Development Agency
            </p>
          </div>
          <div class="container OCSStudio_TextBlock">
            <div class="topTextSection_OCS_Digital">
              <p>
                OSC Digital specialises in bespoke websites, mobile app design and development. OCS Digital is a team of designers and web developers providing well thought out user experience and beautifully functional design.
              </p>
            </div>
            <div class="mediumTextSection">
              <p>Visit our OCS Digital website to find our more</p>
            </div>
            <div class="outerBTN">
              <div class="buttonDiv DigitalOCS">
                <a href="https://ocs-digital.com/">
                  <button type="button" class="btn btn-primary visitOCSDigital">
                    Visit OCS Digital 
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <!-- OCS STUDIOS -->
      <div class="col-lg-4 col-sm-12 backgroundImageOCSDigital">
        <div class="outerBlockOCS_studios">
          <div class="logoOCSStudioTop">
            <img
              class="logoPos ocsStudiosLogo"
              src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/11/SimPark_white.png"
            />
          </div>
          <!-- <div class="MainHeaderText">
            <span class="HeaderText_OCS_Studio">Digital design & development <br> studios specialising in ...</span>
          </div> -->
          <div class="SpecialisingText">
            <p class="studioSpecialsing">
              Indoor Flight, Racing and Golf Simulator studio in Sussex
            </p>
          </div>
          <div class="container OCSStudio_TextBlock">
            <div class="topTextSection_OCS_Studio">
              <p>
                Our facility combines the latest in technology with unmatched entertainment to bring you a world-class venue for enthusiasts of aviation,
                motorsports, and golf
              </p>
            </div>
            <div class="mediumTextSection">
              <!-- <p>OCS software is a team of CGI artists, designers and web developers & providing well thought out user experience, beautifully functional design and out-of-this-world digital imaging to our clients.</p> -->
              <p>
                Visit our SimPark website
              </p>
            </div>
            <div class="outerBTN">
              <div class="buttonDiv StudiosOCS">
                <a href="https://simpark.club/">
                  <button type="button" class="btn btn-primary visitOCSStudio">
                    Visit SimPark
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
.outerBTN {
  /* position: absolute;
  bottom: 8em;
  left: 0;
  right: 0; */
}
.outerBlockOCS_Sport {
  padding-left: 50px;
  padding-right: 50px;
}
.outerBlockOCS_studios {
  padding-left: 50px;
  padding-right: 50px;
}
.StudiosOCS {
  /* margin-top: 190px !important; */
}
.DigitalOCS {
  /* margin-top: 123px !important; */
}
.ocsLogo {
  /* width: 38%; */
  /* margin-top: 152px !important; */
}
.ocsStudiosLogo {
  width: 260px!important;
}
.ocsDigitalLogo {
  /* width: 203px; */
}
body {
  overflow-x: hidden;
}
.backgroundImageOCSSport {
  background-image: url("https://wp-ocssport.ocs-sport.com/wp-content/uploads/2023/03/golfbackground.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.backgroundImageOCSStudios {
  background-image: url("https://wp-ocssport.ocs-sport.com/wp-content/uploads/2023/05/Group-16.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.backgroundImageOCSDigital {
  background-image: url("https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/11/MainBanner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}
img.logoPos {
  width: 180px;
  margin: auto;
  text-align: center;
  display: block;
}
.logo {
  margin-top: 202px;
}
.logoOCSStudioTop {
  margin-top: 250px;
}
.logoOCSStudio {
  margin-top: 173px;
}
.MainHeaderText {
  text-align: center;
  margin-top: 21px;
}

/*OCS SPORT CODE*/
.HeaderText {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #ffffff;
  font-weight: 100;
  font-size: 46px;
  display: block;
  line-height: 0.8;
}
.HeaderBold {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #00aeef;
  font-size: 30px;
  font-weight: bold;
}
.topTextSection {
  text-align: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 17px;
}
.mediumTextSection {
  text-align: center;
  color: #fff;
  font-size: 17px;
  font-weight: 100;
  margin-top: 50px;
}
.OCSSport_TextBlock {
  margin-top: 50px;
  /* width: 650px; */
}
.buttonDiv {
  text-align: center;
  /* margin-top: 100px; */
  /* position: absolute;
  left: 0;
  right: 0;
  bottom: 15%; */
}
button.visitOCSSport {
  background: #00aeef 0% 0% no-repeat padding-box;
  border-radius: 0;
  border: none;
  width: 200px;
  font-size: 13px;
}
button.visitOCSSport:hover {
  /* UI Properties */
  background: #003460 0% 0% no-repeat padding-box;
  border-radius: 0;
  border: none;
  font-size: 13px;
}

/*END OF OCS SPORT CODE*/

/*OCS STUDIO CODE*/

.HeaderText_OCS_Studio {
  font-size: 30px;
  color: #fff;
  font-weight: 100;
}
p.studioSpecialsing {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  margin-top: 50px;
}
p.DigitalSpecialsing {
  color: #e83e4b;
  font-size: 30px;
  font-weight: bold;
  margin-top: 50px;
}
.SpecialisingText {
  text-align: center;
  margin-top: 40px;
}
.topTextSection_OCS_Studio {
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
}
.topTextSection_OCS_Digital {
  text-align: center;
  color: #1ee1b3;
  font-weight: 500;
  font-size: 17px;
}
.buttonDiv {
  text-align: center;
  /* margin-top: 100px; */
}
button.visitOCSStudio {
  background: #DD112B 0% 0% no-repeat padding-box;
  border-radius: 0;
  border: none;
  width: 200px;
  font-size: 13px;
}
button.visitOCSStudio:hover {
  /* UI Properties */
  background: #000 0% 0% no-repeat padding-box;
  border-radius: 0;
  border: none;
  font-size: 13px;
}
button.visitOCSDigital {
  background: #e83e4b 0% 0% no-repeat padding-box;
  border-radius: 0;
  border: none;
  width: 200px;
  font-size: 13px;
}
button.visitOCSDigital:hover {
  /* UI Properties */
  background: #1ee1b3 0% 0% no-repeat padding-box;
  border-radius: 0;
  border: none;
  font-size: 13px;
}
.OCSStudio_TextBlock {
  margin-top: 52.5px;
  /* width: 650px; */
}

/*END OF OCS STUDIO CODE*/

/*media querys*/

@media only screen and (max-width: 1400px) {
  .OCSSport_TextBlock {
    margin-top: 60px;
    width: auto !important;
  }
  .OCSStudio_TextBlock {
    margin-top: 35.5px;
    width: auto !important;
  }
  .buttonDiv {
    text-align: center;
    margin-top: 35px;
    /* margin-top: 100px; */
    /* position: absolute;
    left: 0;
    right: 0;
    bottom: 5%; */
  }
  .buttonDiv.StudiosOCS {
    margin-top: 88px;
  }
  .buttonDiv.DigitalOCS {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 1280px) {
  .OCSSport_TextBlock {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 1200px) {
  .buttonDiv {
      text-align: center;
      /* margin-top: 100px; */
      /* position: relative!important;
      left: 0;
      right: 0;
      bottom: 36px; */
  }
}
@media only screen and (max-width: 1024px) {
  img.logoPos {
    width: 100px;
  }
  .logo {
    margin-top: 50px;
  }
  .logoOCSStudio {
    margin-top: 50px;
  }
  .HeaderBold {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #00aeef;
    font-size: 25px;
    font-weight: bold;
  }
  p.studioSpecialsing {
    color: #7ebd63;
    font-size: 25px;
    font-weight: bold;
    margin-top: 50px;
  }
  p.DigitalSpecialsing {
    color: #e83e4b;
    font-size: 25px;
    font-weight: bold;
    margin-top: 50px;
  }
}
@media only screen and (max-width: 990px) {
  .logoOCSStudioTop {
    margin-top: 50px;
  }
  .ocsStudiosLogo {
    width: 160px !important;
  }
  .outerBlockOCS_Sport {
    padding-left: 0px;
    padding-right: 0px;
  }
  .outerBlockOCS_studios {
    padding-left: 0px;
    padding-right: 0px;
  }
  .backgroundImageOCSSport {
    height: auto !important;
  }
  .backgroundImageOCSStudios {
    height: auto !important;
  }
  .backgroundImageOCSDigital {
    height: auto !important;
  }
  .buttonDiv {
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 500px) {
  .logo {
    margin-top: 100px;
  }
  .logoOCSStudio {
    margin-top: 100px;
  }
}
</style>
